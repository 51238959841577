import { io } from "socket.io-client";
import Hls from 'hls.js';
//import { Hls } from 'hls.js/dist/hls.js';

//let url = "https://dash.akamaized.net/envivio/Envivio-dash2/manifest.mpd";
//let player = MediaPlayer().create();
//player.initialize(document.querySelector('#myMainVideoPlayer'), url, true);

if (Hls.isSupported()) {
    var video = document.getElementById('video');
    var hls = new Hls();
    // bind them together
    hls.attachMedia(video);
    // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !');
    });
}


//let hostname = '88.88.88.88';
//hostname = "localhost";

//console.log(window.location.origin);
const origin = window.location.origin; //`http://${hostname}:8080`;
let loopMode = 0;
let idxVideo = 0;
let serverVideos = [];
let queue = [];
let validExtensions = ['mp4', 'ts', 'avi'];

window.onload = function() {
    // populate #playlist
    loadUrlToPlaylist('videos')
    .then(() => changeSource(idxVideo));
};


//Ids of interactions
const media = document.getElementById("video");
const controlsMenu = document.getElementById("controls");
const cont_playlist = document.getElementById("playlist");
const b_playlistOrder = document.getElementById("playlistOrder");
const b_repeat = document.getElementById("repeat");
const b_sync2me = document.getElementById("sync2me");
const b_restart = document.getElementById("restart");
const b_playpause = document.getElementById("playpause");
const b_rewind = document.getElementById("rew");
//const b_forward5 = document.getElementById("forward5");
const b_fastForward = document.getElementById("fastForward");
const b_volumeUp = document.getElementById("volumeUp");
const b_volumeDown = document.getElementById("volumeDown");
const d_mediaProgressBar = document.getElementById("mediaProgressBar");
const s_mediaProgressAmount = document.getElementById("mediaProgressAmount");
const l_mediaProgressTimer = document.getElementById("mediaProgressTimer");
const p_message = document.getElementById("global_message");

//Icons of interactions
const orderAlphaIcon = '<span class="material-symbols-outlined">sort_by_alpha</span>';
const orderMtimeIcon = '<span class="material-symbols-outlined">calendar_clock</span>';
const restartIcon = '<span class="material-symbols-outlined">restart_alt</span>';
const syncIcon = '<span class="material-symbols-outlined">sync_alt</span>';
const prevIcon = '<span class="material-symbols-outlined">skip_previous</span>';
const playIcon = '<span class="material-symbols-outlined highlighted">play_arrow</span>';
const pauseIcon = '<span class="material-symbols-outlined">pause</span>';
const replay5Icon = '<span class="material-symbols-outlined">replay_5</span>';
const forward5Icon = '<span class="material-symbols-outlined">forward_5</span>';
const fastRewindIcon = '<span class="material-symbols-outlined">fast_rewind</span>';
const fastForwardIcon = '<span class="material-symbols-outlined">fast_forward</span>';
const volumeUpIcon = '<span class="material-symbols-outlined">volume_up</span>';
const volumeDownIcon = '<span class="material-symbols-outlined">volume_down</span>';
const repeatIcon = '<span class="material-symbols-outlined">repeat</span>';
const repeatOnIcon = '<span class="material-symbols-outlined">repeat_on</span>';
const repeatOneIcon = '<span class="material-symbols-outlined">repeat_one</span>';
const autostopIcon = '<span class="material-symbols-outlined">autostop</span>';
const fullscreenIcon = '<span class="material-symbols-outlined">fullscreen</span>';

const nextLoopMode = () => {
    loopMode++; loopMode %= 3;
    b_repeat.innerHTML = (loopMode == 0) ? repeatOneIcon :
                         (loopMode == 1) ? repeatIcon :
                         autostopIcon;
}

//Init Icons
nextLoopMode(); // b_repeat init
b_sync2me.innerHTML = syncIcon;
b_restart.innerHTML = prevIcon; // restartIcon;
b_playpause.innerHTML = media.paused ? playIcon : pauseIcon;
b_rewind.innerHTML = replay5Icon;
b_fastForward.innerHTML = fastForwardIcon;
b_volumeUp.innerHTML = volumeUpIcon;
b_volumeDown.innerHTML = volumeDownIcon;
b_playlistOrder.innerHTML = orderAlphaIcon;

const sortByMtime = (a,b) => Date.parse(b.mtime)-Date.parse(a.mtime);
const sortByName = (a,b) => ('' + a.name).localeCompare(b.name);
let sortFn = sortByMtime;

//client controls and media controls
const myRestart = function () {
    resetControlsHidingTimeout();
    socket.emit('c-videoRestart');
    videoSeek({ paused: true, currentTime: 0 });
}
const videoSeek = function({ paused, currentTime }) {
    media.currentTime = currentTime;
    if (paused) media.pause(); else media.play();
    b_playpause.innerHTML = media.paused ? playIcon : pauseIcon;
}

const myTogglePlayPause = function () {
    resetControlsHidingTimeout();
    socket.emit('c-togglePlayPause', media.currentTime);
    mediaTogglePlayPause();
}
const mediaTogglePlayPause = function () {
    if (media.paused) {
        media.play();
        b_playpause.innerHTML = pauseIcon;
        console.log("play");
    } else {
        media.pause();
        b_playpause.innerHTML = playIcon;
        console.log("pause");
    }
}

const myForcePause = function () {
    resetControlsHidingTimeout();
    socket.emit('c-forcePause', media.currentTime);
    forcePause();
}
const forcePause = function() { media.pause() }

const myRewind = function () {
    resetControlsHidingTimeout();
    socket.emit('c-rewind', 5);
    mediaRewind();
}
const mediaRewind = function () {
    console.log("Prev time:", media.currentTime);
    media.currentTime -= 5;
}

const myForward = function () {
    resetControlsHidingTimeout();
    socket.emit('c-forward', 5);
    mediaForward();
}
const mediaForward = function () {
    console.log("Prev time:", media.currentTime);
    media.currentTime += 5;
}

const getMediaVolumePercentage = () => parseInt(Math.round(media.volume * 100));
const myVolumeUp = function() {
    resetControlsHidingTimeout();
    if (media.volume <= 0.80) media.volume += 0.05;
    else if (media.volume < 0.98) media.volume += 0.01;
    else media.volume = 1;
    global_message(`${getMediaVolumePercentage()}%`);
}
const myVolumeDown = function() {
    resetControlsHidingTimeout();
    if (media.volume >= 0.10) media.volume -= 0.05;
    else if (media.volume > 0.02) media.volume -= 0.01;
    else media.volume = 0;
    global_message(`${getMediaVolumePercentage()}%`);
}

const myTogglePlaylistOrder = () => {
    if (sortFn == sortByMtime) {
        sortFn = sortByName;
        b_playlistOrder.innerHTML = orderMtimeIcon;
        console.log("sFn changed to ByName");

    }
    else {
        sortFn = sortByMtime;
        b_playlistOrder.innerHTML = orderAlphaIcon;
        console.log("sFn changed to ByMtime");
    }
    loadVideosToPlaylist(serverVideos);
}


const getPartnerMediaState = function () {
    socket.emit('c-syncRequest');
    console.log('Requested sync to the server');
}

const getMyMediaState = function () {
    return {
        paused: media.paused,
        currentTime: media.currentTime,
    };
}

const mediaSyncResponse = null;

const commandChangeVideoSource = function ( text ) {
    socket.emit('c-changeVideoSource', text );
}
const mediachangeVideoSource = function( data ) {
    media.src = window.location.origin + data;
    b_playpause.innerHTML = media.paused ? playIcon : pauseIcon;
}

const commandPushSeekVideoToMyTime = function () {
    let paused = media.paused;
    let currentTime = media.currentTime;
    socket.emit('c-videoSeekCurrenttime', { paused, currentTime } );
}

const myChangeSource = function(idxPlaylist) {
    socket.emit('c-videoSourceChange', getVideoPath(idxPlaylist));
    changeSource(idxPlaylist);

}

// function secondsUTC() { return parseInt(Date.parse('2022-01-03T20:18:05.833Z') / 1000); }
function secondsUTC() { return Math.floor((new Date()).getTime() / 1000); }
function getVideoTimeReference() { return secondsUTC() - (media?.currentTime ?? 0) }

setInterval(
    function() {
        if (! media.paused)
        socket.emit(
            'c-videoProgressHeartbeat',
            { ct: (media?.currentTime ?? 0 ), ts: (new Date()) }  //getVideoTimeReference()
        ) },
    5 * 1000
)

const getVideoPath = idxPlaylist => (serverVideos && serverVideos[0]) ? `videos/${serverVideos[queue[idxPlaylist]].name}` : '';

let isFirstVideo = true;
const changeSource = function(idxPlaylist) {
    let videoPath = getVideoPath(idxPlaylist);
    updateSelected(idxPlaylist);
    idxVideo = idxPlaylist;
    media.src = videoPath;
    media.load();
    media.play();
    if (isFirstVideo) { media.volume = 0.5; isFirstVideo = false; }
    b_playpause.innerHTML = media.paused ? playIcon : pauseIcon;
};

const videoElapsedTime = function() {
    // if the video is loaded and duration is known
    if(!isNaN(this.duration)) {
         var percent_complete = this.currentTime / this.duration;
         // use percent_complete to draw a progress bar
         s_mediaProgressAmount.style.width = `${percent_complete * 100}%`; //.value = Math.floor(percent_complete * 100);
         let currentMin = Math.floor(this.currentTime / 60);
         let currentSec = Math.floor(this.currentTime % 60);
         let durationMin = Math.floor(this.duration / 60);
         let durationSec = Math.floor(this.duration % 60);
         if(currentSec < 10) currentSec = `0${currentSec}`;
         if(durationSec < 10) durationSec = `0${durationSec}`;
         l_mediaProgressTimer.innerHTML = `${currentMin}:${currentSec}/${durationMin}:${durationSec}`;
     }
     else {
        s_mediaProgressAmount.style.width = '0%'; //.value = 0;
        l_mediaProgressTimer.innerHTML = `--:--`;
     }
};

const progressSeek = (e) => {
    let seekTime = media.duration * (e.offsetX / e.target.offsetWidth);
    media.currentTime = Math.floor(seekTime);
};

const myFastForward = _ => { media.playbackRate += 0.25; }
const myResetFastForward = _ => { media.playbackRate = 1.0; }


b_playlistOrder.addEventListener('click', myTogglePlaylistOrder);
b_repeat.addEventListener('click', nextLoopMode);
b_sync2me.addEventListener("dblclick", commandPushSeekVideoToMyTime );
b_restart.addEventListener("dblclick", myRestart);
b_playpause.addEventListener("click", myTogglePlayPause);
b_playpause.addEventListener("contextmenu", function(e) { e.preventDefault(); myForcePause(); });
b_rewind.addEventListener("click", myRewind);
b_fastForward.addEventListener("dblclick", myFastForward);
b_fastForward.addEventListener("click", myResetFastForward);
b_volumeUp.addEventListener("click", myVolumeUp);
b_volumeDown.addEventListener("click", myVolumeDown);
d_mediaProgressBar.addEventListener("click", progressSeek);
media.addEventListener("timeupdate", videoElapsedTime);
media.addEventListener("ended", () => {
    if (loopMode == 1) idxVideo++;
    idxVideo %= serverVideos.length;
    if (loopMode !== 2) changeSource(idxVideo);
});
media.addEventListener('dblclick', () => {
    console.log("video dblck", document.fullscreenElement)
    const fs = () => {
        if (media.requestFullscreen) { media.requestFullscreen(); }
        //else if (media.mozRequestFullScreen) { media.mozRequestFullScreen(); }
        else if (media.webkitRequestFullscreen) { media.webkitRequestFullscreen(); }
        else if (media.msRequestFullscreen) { media.msRequestFullscreen(); }
    }
    const exitFs = () => {
        if (document.exitFullscreen) { document.exitFullscreen(); }
        else if (document.webkitExitFullscreen) { /* Safari */ document.webkitExitFullscreen(); }
        else if (document.msExitFullscreen) { /* IE11 */ document.msExitFullscreen(); }
    }
    document.fullscreenElement && exitFs() || fs();
})

//Connection
const socket = io(origin, {
    path: '/node/',
    transports: ['websocket'],
});

//Interactions with server
socket.on('s-videoSeekCurrenttime', function( data ){
    console.log('Recieved videoSeekCurrenttime', data);
    videoSeek(data);
});

socket.on('s-togglePlayPause', function( payload ){
    console.log('Recieved togglePlayPause');
    mediaTogglePlayPause();
    global_message(`${(media.paused) ? 'Paused' : 'Playing'}`);
});

socket.on('s-forcePause', function( payload ){
    console.log('Recieved forcePause');
    forcePause();
    global_message(`${(media.paused) ? 'Paused' : 'Playing'}`);
});

socket.on('s-rewind', function( payload ){
    console.log('Recieved rewind');
    mediaRewind();
    global_message(`Backwards -${payload || 5}`);
});

socket.on('s-forward', function( payload ){
    console.log('Recieved forward');
    mediaForward();
    global_message(`Forward +${payload || 5}`);
});

socket.on('s-getMediaState', function( payload ){
    console.log('Recieved getMediaState');
    socket.emit('c-getMediaState', getMyMediaState());
});

// socket.on('s-syncResponse', function( data ){
//     console.log('Recieved syncResponse');
//     mediaSyncResponse(data);
// });

socket.on('s-videoSourceChange', function( data ){
    console.log('Recieved videoSourceChange');
    //mediachangeVideoSource(data);
    global_message(`Your mate is now playing "${data}"`);
});

socket.on('s-videoProgressHeartbeatWarning', function({ ct, ts } /* i */){
    console.log('Recieved videoProgressHeartbeatWarning', ct, ts );
    const myCt = 1000 * media?.currentTime ?? 0;
    const nTs = Date.parse(ts)
    console.log(Math.floor(ct/60), ct%60 )
    console.log(Math.floor(myCt/1000/60), (myCt/1000)%60 )
    const diff = nTs+ct*1000 - Date.now()+myCt
    console.log( nTs+ct*1000, Date.now()+myCt )
    console.log(diff, Math.floor(diff/1000/60), (diff/1000)%60 )
    if ( (nTs+ct*1000) - (Date.now()+myCt) < -5*1000 ) {
        //const trueTime = secondsUTC()-i;
        console.log('startTime', Date.now()-myCt, 'videoStart', nTs-ct*1000)
        console.log(myCt, ct)
        media.currentTime = ct;
        //media.currentTime = trueTime;
    }
});

const clickFunc = (idx) => (evt) => {
    myChangeSource(idx);
}

// Add to playlist
const loadUrlToPlaylist = async(url) => {
    return fetch(url)
    .then(async(res) => res.json())
    .then(async(data) => { serverVideos = data; return data;})
    .then(loadVideosToPlaylist);
}


let loadVideosToPlaylist = async(serverVideos) => {
    cont_playlist.innerHTML = '';
    let data = serverVideos.sort( sortFn ); idxVideo = 0;
    console.log('playlist videos: ', data);
    for(let i=0; i<data.length; i++) {
        let file = data[i];
        //skip directories
        if (file.type == 'directory') continue;
        //skip other files
    	let extension = file.name.split('.').pop();
        if (! validExtensions.includes(extension) ) continue;
        // download anchor
        let download = document.createElement('a');
        download.innerHTML = 'download';
        download.href = `videos/${file.name}`;
        download.download = file.name;
        download.classList.add('material-symbols-outlined');
        //create a paragraph w/innetHTML w/onclick
        let element = document.createElement('p');
        element.appendChild(download);
        element.innerHTML = `${idxVideo + 1}. ${element.innerHTML}${file.name}`;
        element.addEventListener('click', clickFunc(idxVideo++));
        //AppendParagraph
        //queue.push(file);
        queue.push(i); //real index
        cont_playlist.appendChild(element);
    }
    idxVideo = 0; updateSelected(idxVideo);
    console.log('serverVideos', serverVideos);
    return;
}

let updateSelected = (idx) => {
    let els = document.querySelectorAll('#playlist .selected');
    for(let el of els) { el.classList.remove('selected'); }
    let target = document.querySelector(`#playlist p:nth-child(${idx +1})`);
    //make sure the directory exists and contains available media
    target.classList.add('selected');
};

//addListeners
window.addEventListener("keydown", e => {
    switch(e.code) {
        case 'Space': myTogglePlayPause(); break;
        case 'ArrowLeft': myRewind(); break;
        case 'ArrowRight': myForward(); break;
        case 'ArrowUp': myVolumeUp(); break;
        case 'ArrowDown': myVolumeDown(); break;
    }
});

let clientState = "";

socket.on('s-clientJoined', function( payload ){
    console.log(`${payload} joined the session`);
    global_message(`A new mate joined :)`);
});

socket.on("s-state", ( payload ) => {
    if (clientState !== payload) loadUrlToPlaylist('videos');
    clientState = payload;
});

socket.on("connect", () => {
    console.log(`Connection established: ${socket.id}`);
    global_message(`Connected`);
});

socket.on("connect_error", (err) => {
    console.log(`connect_error due to ${err.message}`);
    global_message(`Connection error...`);
});

socket.on('disconnect', function() {
    console.log('Connection lost');
    global_message(`Connection lost...`);
});


let messageTimeout = 5000;
let timeoutGlobalMessageId = setTimeout(() => null, 1);
const global_message = function (msg) {
    p_message.classList.remove("invisible");
    p_message.innerHTML = msg;
    clearTimeout(timeoutGlobalMessageId);
    timeoutGlobalMessageId = setTimeout(function(){p_message.classList.add("invisible");}, messageTimeout);

}

//Menu Controls
let timeoutTime = 20000;
let timeoutId = setTimeout(function(){controlsMenu.classList.add("hide");}, timeoutTime);
const resetControlsHidingTimeout = function() {
    if(! timeoutId) controlsMenu.classList.remove("hide");
    //restart the timeout
    timeoutTime = media.paused ? 5000 : 2000;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function(){controlsMenu.classList.add("hide"); timeoutId = null;}, timeoutTime);
}
document.onmousemove = resetControlsHidingTimeout;
